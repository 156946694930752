<template>
  <div class="hoverable" @mouseover="handleMouseOver" @mouseleave="hover = false">
    <router-link
      :to="{ name: route, params: routeParams }"
      @click.native="$emit('clicked', $event)"
    >
      {{ value.reference }} {{ includeAddressInLinkText ? `| ${value.address}` : '' }}
    </router-link>

    <div v-if="entity" :class="['popup', displayClass]" :style="popupStyle">
      <div v-if="entity">
        <div class="address-container">
          <span :style="{ color, backgroundColor }" class="entity-type">
            {{ value.type === 'property' || value.type === 'lead' ? entity.type.name_nl : 'Project' }}
          </span>
          <span class="address">{{ entity.address }}</span>
        </div>

        <div class="content">
          <div class="content-row">
            <img
              :src="image"
              alt="Pand foto"
              class="picture tw-max-h-60 tw-object-cover"
            />

            <span class="info">
              <span class="column">
                <span class="title">Referentie</span><br>
                <span class="value">
                  <router-link
                    :to="{ name: route, params: { id: entity.id } }"
                    target="_blank"
                  >
                    {{ entity.reference }}
                  </router-link>
                </span>
                <br>

                <template v-if="value.type === 'property' || value.type === 'lead'">
                  <span class="title">Status</span><br>
                  <span class="value">{{ entity.status_display }}</span><br>

                  <span class="title">Concept</span><br>
                  <span class="value">{{ entity.concept_display }}</span><br>

                  <template v-if="entity.price">
                    <template v-if="entity.concept.type === 0">
                      <span class="title">Prijs<br></span>
                      <span class="value">€ {{ entity.price }}<span v-if="entity.status === 3 || entity.status === 11">/maand</span><br></span>
                    </template>

                    <template v-if="entity.concept.type === 1 || entity.concept.type === 2 || entity.concept.type === 3">
                      <span class="title">{{ entity.concept.type === 1 ? 'Richtprijs' : 'Prijs' }}<br></span>
                      <span class="value">€ {{ entity.price }}<br></span>
                    </template>
                  </template>

                  <template v-if="entity.concept.type === 3">
                    <span class="title">Huurprijs<br></span>
                    <span class="value">€ {{ entity.concept.amount1 }}/maand<br></span>
                  </template>
                </template>

                <span v-else>
                  <span class="title">Naam</span><br>
                  <span class="value">{{ entity.name }}</span><br>

                  <span class="title">Aantal entiteiten</span><br>
                  <span class="value">{{ entity.number_of_entities }}</span><br>
                </span>
              </span>

              <span v-if="value.type === 'property' || value.type === 'lead'" class="column">
                <span class="title">Perceeloppervlakte</span><br>
                <span class="value" v-if="entity.surface_plot && entity.surface_plot !== '0.0'">{{ entity.surface_plot }}m²</span>
                <span class="value" v-else>-</span><br>

                <span class="title">Bebouwde oppervlakte</span><br>
                <span class="value" v-if="entity.surface_built && entity.surface_built !== '0.0'">{{ entity.surface_built }}m²</span>
                <span class="value" v-else>-</span><br>

                <span class="title">Bewoonbare oppervlakte</span><br>
                <span class="value" v-if="entity.surface_livable && entity.surface_livable !== '0.0'">{{ entity.surface_livable }}m²</span>
                <span class="value" v-else>-</span><br>

                <span class="title">Handelsoppervlakte</span><br>
                <span class="value" v-if="entity.surface_trading && entity.surface_trading !== '0.0'">{{ entity.surface_trading }}m²</span>
                <span class="value" v-else>-</span><br>

                <span class="title">Slaapkamers</span><br>
                <span class="value" v-if="entity.num_bedrooms && entity.num_bedrooms !== '0'">{{ entity.num_bedrooms }}</span>
                <span class="value" v-else>-</span><br>

                <span class="title">Zeezicht</span><br>
                <span class="value">{{ entity.sea_view_display }}</span><br>
              </span>
            </span>
          </div>
          <div class="content-row">
            <span class="description">
              <span class="title">Beschrijving</span><br>
              <span>{{ entity.description }}</span>
            </span>
          </div>
          <footer v-if="entity.stickers.length" class="stickers">
            <span v-for="sticker in entity.stickers" :key="sticker.id" :style="labelStyle(sticker)" class="label label-default">
              {{ sticker.sticker.name }}
            </span>
          </footer>
        </div>
      </div>
      <div v-else>
        <Loader />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from './Loader'
import DummyImage from '@/assets/img/property_dummy.gif'

import { getProjectHoverable } from '@/services/projects'
import { getPropertyHoverable } from '@/services/properties'

export default {
  name: 'EntityHoverable',
  components: {
    Loader
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    displayDirection: {
      type: String,
      default: 'right'
    },
    includeAddressInLinkText: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      entity: null,
      hover: false,
      popupStyle: {}
    }
  },
  computed: {
    image () {
      return this.entity?.picture?.url || DummyImage
    },
    route () {
      switch (this.value.type) {
        case 'property':
          return 'PropertyDetails'
        case 'lead':
          return 'LeadDetails'
        default:
          return 'ProjectDetails'
      }
    },
    routeParams () {
      return this.value.type === 'lead'
        ? { id: this.value.match }
        : { id: this.value.id }
    },
    displayClass () {
      return this.displayDirection === 'right'
        ? 'popup-right'
        : 'popup-left'
    },
    color () {
      if (this.entity) {
        switch (this.entity.brand) {
          case 1:
          case 2:
          case 5:
            return '#FFFFFF'
          case 4:
            return '#a8a37e'
          default:
            return 'inherit'
        }
      }
      return 'inherit'
    },
    backgroundColor () {
      if (this.entity) {
        switch (this.entity.brand) {
          case 1:
            return '#ee3124'
          case 2:
            return '#3aa9e3'
          case 4:
            return '#204361'
          case 5:
            return '#59bcb1'
          default:
            return 'inherit'
        }
      }
      return 'inherit'
    }
  },
  watch: {
    hover (newVal) {
      const entityLoaded = this.value?.id === this.entity?.id
      if (newVal && !entityLoaded) {
        this.value.type === 'property' || this.value.type === 'lead'
          ? this.getPropertyData(this.value.id)
          : this.getProjectData(this.value.id)
      }
    }
  },
  methods: {
    labelStyle (sticker) {
      return `background-color: ${sticker.sticker.color}; color: white;`
    },
    updatePopupPosition (event) {
      const rect = event.target.getBoundingClientRect()
      const spaceBelow = window.innerHeight - rect.bottom
      const spaceAbove = rect.top
      const positionBelow = spaceBelow > spaceAbove

      this.popupStyle = {
        top: positionBelow ? `${rect.bottom}px` : 'auto',
        bottom: !positionBelow ? `${window.innerHeight - rect.top}px` : 'auto',
        left: this.displayDirection === 'right' ? `${rect.right + 10}px` : 'auto',
        right: this.displayDirection === 'left' ? `${window.innerWidth - rect.left + 10}px` : 'auto'
      }
    },
    handleMouseOver (event) {
      this.hover = true
      this.updatePopupPosition(event)
    },

    async getProjectData (projectId) {
      const response = await getProjectHoverable(projectId)
      this.entity = response.data
      return response
    },
    async getPropertyData (propertyId) {
      const response = await getPropertyHoverable(propertyId)
      this.entity = response.data
      return response
    }
  }
}
</script>

<style scoped>
.hoverable {
  position: relative;
}

.hoverable .popup {
  z-index: 10000;
  display: none;
  visibility: hidden;
  position: fixed;
  background-color: #f5f5f5;
  top: 0;
  padding: 0 0 10px 0;
  width: 750px;
  height: inherit;
  border: 1px solid #676a6c;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  transition: display .3s ease, visibility .3s ease;
}

.hoverable:hover .popup {
  display: block;
  visibility: visible;
}

.popup-right {
  left: 150px;
}

.popup-left {
  right: 150px;
}

.address-container {
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid #676a6c;
  padding: 0 20px 0 0;
}

.address {
  font-weight: bold;
  width: 70%;
  padding: 10px 0 8px 20px;
  display: inline-block;
}

.entity-type {
  padding: 10px 20px 10px 20px;
  border-right: 1px solid #676a6c;
  width: 30%;
  display: inline-block;
  float: left;
  text-align: center;
  border-top-left-radius: 9px;
}

.content-row {
  width: 100%;
  display: inline-block;
}

.content {
  width: 100%;
  position: relative;
}

.picture {
  max-width: 45%;
  width: 45%;
  float: left;
  border-bottom-right-radius: 10px;
}

.description {
  width: 100%;
  display: inline-block;
  padding: 10px 15px;
}

.description .title,
.column .title,
.entity-type {
  font-weight: bold;
}

.info {
  display: inline-block;
  width: 55%;
  max-width: 55%;
  float: right;
}

.info .column {
  width: 50%;
  max-width: 50%;
  float: left;
  display: inline-block;
  padding: 5px 10px;
}

.stickers {
  padding: 15px;
  color: white;
}
</style>
