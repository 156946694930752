var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',[_c('h2',{attrs:{"slot":"title"},slot:"title"},[_c('small',[_vm._v("Historiek")])]),_c('DataTable',_vm._b({staticClass:"tw--m-5 tw-shadow-none",attrs:{"loading":_vm.loading,"headers":_vm.headers},on:{"changePage":_vm.loadHistory},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('div',{staticClass:"tw-mb-2 tw-flex tw-justify-end"},[_c('button',{staticClass:"action tw-bg-success",attrs:{"type":"button","title":"Dossier koppelen"},on:{"click":_vm.showLinkForm}},[_c('i',{staticClass:"fa fa-link"}),_vm._v(" Dossier koppelen ")])])]},proxy:true},{key:"item-reference",fn:function(ref){
var item = ref.item;
return [_c('EntityHoverable',{attrs:{"value":item}})]}},{key:"item-transaction_intake_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.transaction_data ? ("" + (item.transaction_data.intake_date)) : 'N.v.t.')+" ")])]}},{key:"item-transaction_out_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.transaction_data ? ("" + (item.transaction_data.out_date)) : 'N.v.t.')+" ")])]}},{key:"item-out_price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.transaction_data ? ("" + (item.transaction_data.out_price)) : 'N.v.t.')+" ")])]}},{key:"item-actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-flex"},[_c('button',{staticClass:"action tw-bg-warning",attrs:{"type":"button","title":"Loskoppelen"},on:{"click":function($event){return _vm.unlink(item.id)}}},[_c('i',{staticClass:"far fa-unlink"})]),_c('button',{staticClass:"action",class:item.is_visible_customer_portal ? 'tw-bg-primary' : 'tw-bg-error',attrs:{"type":"button","title":_vm.getVisibleOnPortalButtonTitle(item)},on:{"click":function($event){return _vm.toggleVisibleOnPortal(item)}}},[_c('i',{staticClass:"far fa-globe"})])])]}}])},'DataTable',_vm.history,false)),_c('FormulateForm',{attrs:{"name":"linkPropertyForm"},on:{"submit":_vm.link},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('Modal',{ref:"linkModal",attrs:{"size":"xs","title":"Dossier koppelen"},on:{"hide":function($event){return _vm.$formulate.reset('linkPropertyForm', {})}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"tw-flex tw-flex-row tw-space-x-4 tw-justify-end"},[_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"input-class":['tw-w-full'],"outer-class":['tw-w-full md:tw-w-auto']}},[_c('i',{class:[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-check'
              ]}),_vm._v(" Koppelen ")]),_c('FormulateInput',{attrs:{"type":"button","input-class":['tw-bg-error tw-w-full'],"outer-class":['tw-w-full md:tw-w-auto'],"data-dismiss":"modal"}},[_c('i',{staticClass:"far fa-times tw-mr-2"}),_vm._v(" Annuleren ")])],1)]},proxy:true}],null,true)},[_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"property","name":"property_to_link","label":"Pand","placeholder":"Selecteer een pand","outer-class":"tw-m-0"}})],1)]}}]),model:{value:(_vm.propertyForm),callback:function ($$v) {_vm.propertyForm=$$v},expression:"propertyForm"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }