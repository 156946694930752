<template>
  <Page>
    <h2 slot="title">
      <small>Historiek</small>
    </h2>
    <DataTable
      :loading="loading"
      :headers="headers"
      v-bind="history"
      class="tw--m-5 tw-shadow-none"
      @changePage="loadHistory"
    >
      <template #toolbar>
        <div class="tw-mb-2 tw-flex tw-justify-end">
          <button
            type="button"
            title="Dossier koppelen"
            class="action tw-bg-success"
            @click="showLinkForm"
          >
            <i class="fa fa-link"/> Dossier koppelen
          </button>
        </div>
      </template>
      <template #item-reference="{ item }">
        <EntityHoverable :value="item"/>
      </template>
      <template #item-transaction_intake_date="{ item }">
        <span>
          {{
            item.transaction_data
              ? `${item.transaction_data.intake_date}`
              : 'N.v.t.'
          }}
        </span>
      </template>
      <template #item-transaction_out_date="{ item }">
        <span>
          {{
            item.transaction_data
              ? `${item.transaction_data.out_date}`
              : 'N.v.t.'
          }}
        </span>
      </template>
      <template #item-out_price="{ item }">
        <span>
          {{
            item.transaction_data
              ? `${item.transaction_data.out_price}`
              : 'N.v.t.'
          }}
        </span>
      </template>
      <template #item-actions="{ item }">
        <div class="tw-flex">
          <button
            type="button"
            title="Loskoppelen"
            class="action tw-bg-warning"
            @click="unlink(item.id)"
          >
            <i class="far fa-unlink"/>
          </button>
          <button
            type="button"
            :title="getVisibleOnPortalButtonTitle(item)"
            class="action"
            :class="item.is_visible_customer_portal ? 'tw-bg-primary' : 'tw-bg-error'"
            @click="toggleVisibleOnPortal(item)"
          >
            <i class="far fa-globe" />
          </button>
        </div>
      </template>
    </DataTable>
    <FormulateForm
      #default="{ isLoading }"
      v-model="propertyForm"
      name="linkPropertyForm"
      @submit="link"
    >
      <Modal
        ref="linkModal"
        size="xs"
        title="Dossier koppelen"
        @hide="$formulate.reset('linkPropertyForm', {})"
      >
        <FormulateInput
          type="autocomplete"
          auto-complete-type="property"
          name="property_to_link"
          label="Pand"
          placeholder="Selecteer een pand"
          outer-class="tw-m-0"
        />
        <template #footer>
          <div class="tw-flex tw-flex-row tw-space-x-4 tw-justify-end">
            <FormulateInput
              type="submit"
              :disabled="isLoading"
              :input-class="['tw-w-full']"
              :outer-class="['tw-w-full md:tw-w-auto']"
            >
              <i
                :class="[
                  'fas tw-mr-1',
                  isLoading ? 'fa-spinner-third fa-spin' : 'fa-check'
                ]"
              />
              Koppelen
            </FormulateInput>
            <FormulateInput
              type="button"
              :input-class="['tw-bg-error tw-w-full']"
              :outer-class="['tw-w-full md:tw-w-auto']"
              data-dismiss="modal"
            >
              <i class="far fa-times tw-mr-2" /> Annuleren
            </FormulateInput>
          </div>
        </template>
      </Modal>
    </FormulateForm>
  </Page>
</template>

<script>
import Page from '@/components/iam/Page'
import DataTable from '@/components/DataTable'
import EntityHoverable from '@/components/iam/EntityHoverable'
import Modal from '@/components/iam/Modal'
import { errorModal, questionModal, successModal } from '@/modalMessages'
import {
  getHistoryForProperty,
  unlinkPropertyFromHistory,
  linkPropertyToHistory,
  updatePropertyMarketing
} from '@/services/properties'

export default {
  name: 'PropertyHistory',
  components: { Page, DataTable, EntityHoverable, Modal },
  data () {
    return {
      history: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      propertyForm: {},
      loading: false
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler (value) {
        this.loadHistory({ propertyId: value })
      }
    }
  },
  methods: {
    async loadHistory (payload) {
      try {
        this.loading = true
        const response = await getHistoryForProperty(payload)
        this.history = response.data
        this.loading = false
        return response
      } catch (error) {
        console.error(error)
        errorModal('Fout bij het laden van de historiek, probeer het opnieuw.')
      }
    },
    async unlink (propertyId) {
      try {
        const result = await questionModal('Ben je zeker dat je dit pand wil loskoppelen?')
        if (!result.value) return
        this.loading = true
        await unlinkPropertyFromHistory({ entity_id: propertyId })
        const response = await this.loadHistory({ propertyId: this.propertyId })
        this.loading = false
        return response
      } catch (error) {
        if (error.response?.data?.code === 'PROPERTY_HAS_NO_HISTORY_TO_UNLINK') {
          errorModal('Er is geen historiek om los te koppelen')
        } else {
          errorModal('Fout bij ontkoppelen van de historiek, probeer het opnieuw.')
        }
        this.loading = false
      }
    },
    async link (data) {
      try {
        this.loading = true
        await linkPropertyToHistory(this.propertyId, { entity_id: data.property_to_link.id })
        const response = await this.loadHistory({ propertyId: this.propertyId })
        this.hideLinkForm()
        this.loading = false
        return response
      } catch (error) {
        if (error.response?.data?.code === 'CANT_LINK_PROPERTY_TO_SELF') {
          errorModal('Je kunt een pand niet aan zichzelf koppelen.')
        } else {
          errorModal('Fout bij koppelen van het pand, probeer het opnieuw.')
        }
        this.loading = false
      }
    },
    getVisibleOnPortalButtonTitle (prop) {
      return prop.is_visible_customer_portal ? 'Onzichtbaar maken op klantenportaal' : 'Zichtbaar maken op klantenportaal'
    },
    async toggleVisibleOnPortal (prop) {
      try {
        this.loading = true
        const payload = { is_visible_customer_portal: !prop.is_visible_customer_portal }
        await updatePropertyMarketing(prop.id, payload)
        successModal('De zichtbaarheid van het pand werd aangepast', true)
        return await this.loadHistory({ propertyId: this.propertyId })
      } catch (error) {
        errorModal('Fout bij het wijzigen van de zichtbaarheid op het klantenportaal')
      } finally {
        this.loading = false
      }
    },
    showLinkForm () {
      this.$refs.linkModal.show()
    },
    hideLinkForm () {
      this.$refs.linkModal.hide()
    }
  },
  computed: {
    headers () {
      const headers = [
        { value: 'reference', text: 'Referentie' },
        { value: 'owner', text: 'Eigenaar' },
        { value: 'status', text: 'Status' },
        { value: 'new_resident', text: 'Huurder/koper' },
        { value: 'transaction_intake_date', text: 'Intakedatum' },
        { value: 'transaction_out_date', text: 'Transactiedatum' },
        { value: 'out_price', text: 'Prijs' },
        { value: 'actions', text: 'Actie' }
      ]
      return headers
    },
    propertyId () {
      return this.$route.params.id
    }
  }
}
</script>
